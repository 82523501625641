@use '@angular/material' as mat;
@use 'theme-colors' as sovityColors;
@use 'theme-colors.mds' as mdsColors;
@use 'theme-generated-variables' as themeGeneratedVars;

// Import styles that aren't theme dependant (including typography)
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

$custom-typography: mat.define-typography-config(
  $font-family: 'TeleNeoWeb,sans-serif',
);
@include mat.core($custom-typography);

// Import theme and theme-dependant styles
.theme-sovity {
  @include mat.all-component-themes(sovityColors.$theme);
  @include themeGeneratedVars.theme-vars(
    sovityColors.$theme,
    sovityColors.$link-color
  );
}
.theme-mds {
  @include mat.all-component-themes(mdsColors.$theme);
  @include themeGeneratedVars.theme-vars(
    mdsColors.$theme,
    mdsColors.$link-color
  );
}

// fix paginator background, it was white before, but our background is gray
mat-paginator {
  background: transparent !important;
}

// Fix Icon sizes used in card avatars
mat-icon[mat-card-avatar] {
  font-size: 40px;
}
button > span > mat-icon {
  color: white;
}

.theme-sovity .mat-icon.mat-warn {
  color: #e20074;
}
.mat-badge-warn .mat-badge-content {
  background: #e20074 !important;
}
// Fix mat-chips exploding out and/or having multiline exploding strings
mat-chip {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

mat-icon {
  color: #e20074;
}

.font-large-magenta {
  font-weight: 800;
  color: #e20074;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  background: rgba(0, 0, 0, 0.5) !important;
}

mat-card-title a {
  color: black !important;
}
.theme-sovity .mat-dialog-container {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.1), 0px 24px 96px rgba(0, 0, 0, 0.1);
}

.theme-sovity .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 8px 32px 0px hsl(0deg 0% 0% / 10%), 0px 4px 8px 0px hsl(0deg 0% 0% / 10%);
}
.theme-sovity .mat-card {
  border-radius: 0.75rem;
}
// Fix Date range second-date-text being "higher" than first-date-text
.mat-date-range-input-container {
  align-items: unset !important;
}
