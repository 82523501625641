@forward 'theme';

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.snackbar-info-style {
  background-color: orange;
  color: black;
}

.snackbar-error-style {
  background-color: crimson;
  color: black;
}

.code {
  font-family: monospace;
  font-size: larger;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mat-hint-text-ellipsis {
  mat-hint {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.truncate-lines-5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.asset-card {
  width: 300px;
}

.list-style-material-icons-check {
  list-style-type: none;
  padding-inline-start: 8px;

  li {
    margin-top: 8px;

    &:before {
      margin-right: 8px;
      content: 'check';
      font-family: 'Material Icons';
    }
  }
}

.section-title {
  margin: 16px 3px;
  text-transform: uppercase;
  color: #373737;
  font-size: 14px;
  letter-spacing: 0.04em;
}

// hrefs
.link {
  color: var(--link-color);
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

/*
  Tailwind Style Utility Classes
 */

.w-full {
  width: 100%;
}

.w-\[80px\] {
  width: 80px;
}

.h-\[80px\] {
  height: 80px;
}

.w-\[800px\] {
  width: 800px;
}

.w-1\/3 {
  width: calc(100% / 3);
  max-width: calc(100% / 3);
}

.w-1\/2 {
  width: calc(100% / 2);
  max-width: calc(100% / 2);
}

.min-h-\[300px\] {
  min-height: 300px;
}

.min-h-\[35rem\] {
  min-height: 35rem;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.flex {
  display: flex;
}

.\!flex {
  display: flex !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-even-sized {
  flex: 1 1 0;
}

.space-x-\[5px\] > * + * {
  margin-left: 5px;
}

.space-x-\[4px\] > * + * {
  margin-left: 4px;
}

.space-x-\[10px\] > * + * {
  margin-left: 10px;
}

.\!space-x-\[10px\] > * + * {
  margin-left: 10px !important;
}

.space-y-\[10px\] > * + * {
  margin-top: 10px;
}

.space-y-\[16px\] > * + * {
  margin-top: 16px;
}

.space-y-\[20px\] > * + * {
  margin-top: 20px;
}

.space-x-\[20px\] > * + * {
  margin-left: 20px;
}

.p-\[20px\] {
  padding: 20px;
}

.m-\[20px\] {
  margin: 20px;
}

.mt-\[10px\] {
  margin-top: 10px;
}

.mt-\[40px\] {
  margin-top: 10px;
}

.mb-\[10px\] {
  margin-bottom: 10px;
}

.mt-\[50px\] {
  margin-top: 50px;
}

.gap-\[10px\] {
  gap: 10px;
}

.gap-\[16px\] {
  gap: 16px;
}

// See https://tailwindcss.com/docs/flex-direction
.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

// See https://tailwindcss.com/docs/flex-grow
.grow {
  flex-grow: 1;
}

// See https://tailwindcss.com/docs/flex-shrink
.shrink-0 {
  flex-shrink: 0;
}

.shrink {
  flex-shrink: 1;
}

// See https://tailwindcss.com/docs/align-items
.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

// See https://tailwindcss.com/docs/justify-content
.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

// See https://tailwindcss.com/docs/background-color
.bg-white {
  background: white;
}

// See https://tailwindcss.com/docs/text-color
.text-black {
  color: black;
}

.text-slate {
  color: rgba(0, 0, 0, 0.54);
}

.text-warn {
  color: var(--warn-color);
}

// See https://tailwindcss.com/docs/font-size
.text-sm {
  font-size: 0.8em;
}

// See https://tailwindcss.com/docs/text-align
.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

// See https://tailwindcss.com/docs/whitespace
.whitespace-pre-line {
  white-space: pre-line;
}

// See https://tailwindcss.com/docs/word-break
.break-all {
  word-break: break-all;
}

// See https://tailwindcss.com/docs/text-transform
.uppercase {
  text-transform: uppercase;
}
