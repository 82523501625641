@use '@angular/material' as mat;
@use 'sass:map';

/* Theming (Sovity Stock EDC UI) */

$theme-colors-primary: (
  50: #e0e5ec,
  100: #b3bed0,
  200: #8092b1,
  300: #4d6691,
  400: #26467a,
  500: #e20074,
  600: #00215a,
  700: #001b50,
  800: #001646,
  900: #000d34,
  A100: #6c80ff,
  A200: #3954ff,
  A400: #0628ff,
  A700: #0020eb,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$link-color: #2238df;

$theme-colors-accent: (
  50: #ffffff,
  100: #000000,
  200: #000000,
  300: #000000,
  400: #000000,
  500: #000000,
  600: #000000,
  700: #000000,
  800: #000000,
  900: #000000,
  A100: #000000,
  A200: #000000,
  A400: #000000,
  A700: #000000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$theme-palette-primary: mat.define-palette($theme-colors-primary);
$theme-palette-accent: mat.define-palette($theme-colors-accent);
$theme-palette-warn: mat.define-palette(mat.$red-palette);

$theme: mat.define-light-theme(
  $theme-palette-primary,
  $theme-palette-accent,
  $theme-palette-warn
);
